import commonAct from '@/commonActionHandle.js';
import usersList from "@/scripts/users/usersList";
import lazyLoadComponent from '@/scripts/lazyLoadComponent';
import SkeletonBox from '@/components/SkeletonBox';
import usersApi from '@/api/common/users';
import { debounce } from 'vue-debounce';
import { mapState } from "vuex";
import moment from "moment";
export default {
    extends: usersList,
    methods: {
        getElementsList(pageNumber) {
            this.showLoading();
            this.$store.dispatch('CANCEL_PENDING_REQUESTS');
            usersApi.getUsersBlacklist(pageNumber, this.requestParam).then(response => {
                if (response.data && response.data.result === 0 && response.data.data !== null) {
                    this.elements.data = response.data.data.data;
                    this.elements.pagingItem = response.data.data.pagingItem;
                    this.hideLoading();
                }
                else {
                    this.elements.data = [];
                    this.elements.pagingItem = this.defaultPagingItem;
                    this.hideLoading();
                    if (response.data && response.data.message !== null && response.data.message !== '') {
                        commonAct.showError(response.data.message || this.$lang.common.error);
                    }
                }
            }).catch(error => {
                console.error(error);
                this.elements.data = [];
                this.elements.pagingItem = this.defaultPagingItem;
                this.hideLoading();
                commonAct.showError(error);

            });
        },
        exportData() {
            let host = process.env.VUE_APP_API_HOST.slice(0, -1);
            //window.open(host + usersApi.exportUsersList(1, this.requestParam), '_blank');
            usersApi.exportUsersList(1, this.requestParam).then(response => {
                if (response.data && response.data.result === 0) {
                    this.$message({
                        message: 'Congrats, perform action succeed.',
                        type: 'success'
                    });
                }
            }).catch(error => {
                console.error(error);
            });
        },
    }
}